

.relayClass {
  opacity: 1 !important;
  border: 1px solid rgb(59,59,59) !important;
}
.relayClass.place-top::after,
.relayClass.place-bottom::after {
    color:  rgb(59,59,59) transparent !important;
}

kbd.key {
  border-radius: 2px;
  padding: 2px 5px 2px;
  border: 1px solid #17a2b8;
  background-color: #17a2b8;
}

span.key {
  border-radius: 3px;
  padding: 1px 0px 1px;
  margin-top: 2px;
  margin-right: 1px;
  border: 1px solid #17a2b8;
  background-color: #17a2b8;
}

.used:hover {
  outline: solid;
  outline-width: thin;
  outline-color: rgba(196,201,212,1);
  background-color: #fafafa !important;
}

.important,
.important a {
    color: #fff !important;
    background-color: #ffc107 !important;
}

.important:focus,
.important.focus,
.important a:hover {
    background-color: #33ffd3 !important;
}

.loupe a {color: rgb(44,165,255) !important;}
.loupe a:hover {color: #7ad7ff !important;}

.loupe_disable a {color: #999484 !important;}
.loupe_disable a:hover {cursor: default;}


.btn-spec a {color: rgb(44,165,255) !important;}
.btn-spec a:hover {color: rgb(76,163,199) !important;}

.btn-navbar {
  background-color:#343a40;
}

.btn-navbar:hover, .btn-navbar:focus {
  background-color: rgb(119,114,114);
  outline: none !important;
  box-shadow: none !important;
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 0px;
  font-size: 0px;
  line-height: 1.428571429;
  border-radius: 15px;
}
.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  font-size: 24px;
  line-height: 1.33;
  border-radius: 35px;
}

.error {
  border: 1px solid red;
}

.tableFixHead    { overflow-y: auto; height: 26px; }
.tableFixHead th { position: sticky; top: 0; height: 26px;}


.disable_text_highlighting {
-webkit-user-select: none; /* Webkit */
-moz-user-select: none;    /* Firefox */
-ms-user-select: none;     /* IE 10  */
/* Currently not supported in Opera but will be soon */
-o-user-select: none;
user-select: none;
}

.nav-pills>li>a {
    border: 1px solid transparent;
}

.nav-pills>li>a:hover {
    background: #fff;
    border: 1px solid #b1cdd1;
}

.modalConfirm {
    width: 500px;
    left: 10%;
}

.dropdown-item:hover {background:#2ab4c9; color: #fff; }

.modalAlert {background:#fabebe; color: #691a1a; border-color: #c24545;}

.modalSecurite {border-color: #e02424; border-width: 1px}


.rt-th {background: #575d62; color: #fff;}
.rt-tr {background: #fcfcfc;}
.rt-tr:hover {background: #e9e9e9;}
.rt-th:hover {background: #787b80;}

.ReactTable.-clickable .rt-tbody .rt-tr:not(.-padRow):hover {
  cursor: pointer;
  outline: 1px solid #d9d9d9;
}

/*
.ReactTable .rt-thead {
  overflow-y: scroll;
}*/


/* Just common table stuff. */
table  { border-collapse: collapse; width: 100%; }
th, td { padding: 8px 16px; }
th {background: #343a40; color: #fff;}
tr:hover td {background: #f2f2f2;}
th:hover {background: #494d52;}
tr:hover {cursor: pointer; outline: 1px solid #17a2b8;}

/* NAVIGATION */
nav ul {
  list-style: none;
  text-align: center;
}
nav ul li {
  display: inline-block;
}
nav ul li a,
nav ul li a:after,
nav ul li a:before {
  transition: all .5s;
}
nav ul li a:hover {
  color: #555;
}


/* stroke */
nav.stroke ul li a,
nav.fill ul li a {
  position: relative;
}
nav.stroke ul li a:after,
nav.fill ul li a:after {
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  background: #d1cfcf;
  height: 1px;
}
nav.stroke ul li a:hover:after {
  width: 100%;
}


.navbar-nav li a{
  color: #bfbfbf;
}

.navbar-nav li a:hover{
  color: #e6e6e6;
}

.navbar-nav li > .active{
  color: #fff;
  transition: visibility 0s, opacity 0.5s linear;
  margin-bottom: -1px;
  border-bottom: 1px solid;
}



nav.fill ul li a {
  transition: all 2s;
}

nav.fill ul li a:after {
  text-align: left;
  content: '.';
  margin: 0;
  opacity: 0;
}
nav.fill ul li a:hover {
  color: #fff;
  z-index: 1;
}
nav.fill ul li a:hover:after {
  z-index: -10;
  animation: fill 1s forwards;
  -webkit-animation: fill 1s forwards;
  -moz-animation: fill 1s forwards;
  opacity: 1;
}



/* Keyframes */
@-webkit-keyframes fill {
  0% {
    width: 0%;
    height: 1px;
  }
  50% {
    width: 100%;
    height: 1px;
  }
  100% {
    width: 100%;
    height: 100%;
    background: #333;
  }
}

/* Keyframes */
@-webkit-keyframes circle {
  0% {
    width: 1px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 1px;
    z-index: -1;
    background: #eee;
    border-radius: 100%;
  }
  100% {
    background: #aaa;
    height: 5000%;
    width: 5000%;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 0;
  }
}

.modal-lg{
  min-width: 40vw;
}

.modal-xxl{
  min-width: 70vw;
}

.custom-tooltip-simcard {
  background-color: white !important;
  color: black !important;
  opacity: 1 !important;
  border: 1px solid grey;
}

.sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
  font-size: 95%
}

.marker {
  background-image: url('mapbox-icon.png');
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.map-container {
  min-height: 50vh;
}
