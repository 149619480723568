tbodyscroll { display: block; }

tbodyscroll {
    max-height: 78vh;
    width: auto;
    overflow-x: hidden;
}


body {
    position: relative;
}